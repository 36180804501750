// Migrated
<template lang="pug">
button#charterButtonAirport.charter-search__btn(
  ref="charterButtonAirport",
  :class="{ active: isActive }",
  :aria-expanded="isActive",
  aria-haspopup="true",
  aria-labelledby="charterButtonLabelAirport",
  @click="$emit('click')"
)
  #charterButtonLabelAirport.charter-search__btn-title.d-flex.w-100
    fa.mr-2(v-if="!$isSol" :icon="icons.faPlaneDeparture")
    span {{ $t('charterSearchDeparture') }}
    fa.charter-search__btn-icon.ml-auto.transition-transform(
      v-if="$isSol"
      icon="chevron-down"
      :style="{ transform: `rotateX(${isActive ? '180deg' : '0'})` }"
    )
  .charter-search__btn-text
    span(v-if="airportNotSet") {{ $t('charterSearchSelect') }}
    span(v-else) {{ airportName }}
    fa.charter-search__btn-icon(
      v-if="!$isSol"
      :icon="isActive ? 'caret-square-up' : 'caret-square-down'"
    )
  .charter-search__btn-title.text-error.blink.blink-title(
    v-if="selectAirportFirst"
  ) {{ $t('charterSelectAirportFirst') }}
</template>

<script>
import { faPlaneDeparture } from '@fortawesome/pro-duotone-svg-icons'

export default defineNuxtComponent({
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },

    selectedAirport: {
      type: Object,
      default: null,
    },

    selectAirportFirst: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['click'],

  data () {
    return {
      icons: {
        faPlaneDeparture,
      },
    }
  },

  computed: {
    airportNotSet () {
      return !this.selectedAirport || !this.selectedAirport?.airport_name
    },

    airportName () {
      return this.selectedAirport?.airport_name
    },
  },
})
</script>
